<template>
  <div class="container">
    <div class="container_mask">
      <div class="container_login">
        <div class="container_login_left">
          <div class="container_login_left_title">山东阳光志愿者服务平台</div>
          <div class="container_login_left_pic"><img src="../../assets/images/loginbg.gif" alt=""></div>
        </div>
        <div class="container_login_right">
          <!--                刷卡登录-->
          <div class="container_login_right" >
<!--            <el-input
                ref="refInput"
                v-model="cardnum"
                class="w-50 m-2"
                size="large"
                placeholder="请刷卡登录"
                @keyup.enter="loginClick"
            />-->
            <div class="container_login_right_tips">
              <img src="../../assets/images/right.png">
              <div class="container_login_right_tips_text1">
                扫码成功
              </div>
              <div class="container_login_right_tips_text2">
                获取你的公开信息(昵称、头像等)
              </div>
              <div class="container_login_right_loginbtn">
                <el-button @click="loginClick">确认授权</el-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="WxLogin">


import {ref} from "vue-demi";
import { weChatOpenNotify} from "@/api/user"
import {useRouter} from "vue-router";
import {setToken, setUserId} from "../../utils/auth";

const code = ref('')
const router = useRouter()
const state = ref("");
const loginClick = async () => {
  let response = await weChatOpenNotify({code:code.value,state:state.value});
  if(response!=null && response.code==200){
    if(response.data.token != null){
      setToken(response.data.token)
      setUserId(response.data.user.id)
      router.push({name: 'Home'})
    }else {
      router.push({ name: "BindPhone", params: { user: JSON.stringify(response.data.user) } })
    }
  }

}


const getUrlData = ()=>{
  var url = window.location.href ;
  var cs = url.split('?')[1];                //获取?之后的参数字符串
  console.error('cs'+cs);
  var cs_arr = cs.split('&');
  console.error('cs_arr'+cs_arr);
  var codeRes = cs_arr[0].substring(5,cs_arr[0].length);
  console.error('code-->'+codeRes);
  code.value = codeRes.toString();
  var stateRes = cs_arr[1].substring(6,cs_arr[1].length);
  console.error(stateRes);
  state.value = stateRes.toString();
}
getUrlData();



</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
  width: 55px;
  height: 80px;
  line-height: 20px;
  border-radius: 0 10px 10px 0;
  background-color: rgba(255, 98, 0, 50);
  color: #FFFFFF;
}

.container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/mainbg.png') no-repeat;
  background-size: 100% 100%;

  &_mask {
    background-color: rgba($color: #000000, $alpha: 0.4);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_login {
    width: 50%;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;

    &_left {
      flex: 1;
      background: #fff;
      padding: 30px 50px 30px 30px;
      display: flex;
      flex-direction: column;

      &_title {
        width: 50%;
        color: rgba(255, 98, 0, 100);
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }

      &_pic {
        margin-top: 40px;
      }

      &_pic img {
        height: 320px;
      }
    }

    &_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #FF9959;

      &_tips {
        &_image{

        }
        &_text1{
          margin-top: 20px;
          font-weight: bold;
          font-size: 20px;
          color: rgba(255, 255, 255, 100);
        }
        &_text2{
          margin-top: 20px;
          color: rgba(255, 255, 255, 100);
        }
      }
      &_tips img {
        height: 100px;
      }

      &_loginbtn {
        margin-top: 40px;
      }



    }
  }
}

:deep(.el-input__inner) {

}

:deep(.el-input__wrapper) {
  width: 340px;
  height: 80px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(255, 98, 0, 50);
}

:deep(.el-button) {
  width: 370px;
  height: 80px;
  line-height: 20px;
  border-radius: 10px;
    background-color: rgba(255, 255, 255, 100);
}
</style>
